import { APP } from '../base'
import Flickity from 'flickity'
// import 'flickity-fullscreen'

export class Carousel {
  constructor(selector, options) {
    this.elements = document.querySelectorAll(selector)
    if (!this.elements.length) return

    this.instances = []

    this.elements.forEach((element, i) => {
      const container = element.querySelector('.carousel-container')

      if (element.classList.contains('has-captions')) {
        const captions = container.querySelectorAll('.caption')
        const footer = element.querySelector('footer')
        const caption = footer.querySelector('.wrapper')
        const btnPrev = footer.querySelector('.is-prev')
        const btnNext = footer.querySelector('.is-next')

        Object.assign(options, {
          on: {
            select: (index) => {
              caption.innerHTML = captions[index].innerHTML.trim()
            },
          },
        })

        btnPrev.addEventListener('click', (e) => {
          this.instances[i].previous()
        })

        btnNext.addEventListener('click', (e) => {
          this.instances[i].next()
        })
      }

      requestAnimationFrame(() => {
        element.classList.remove('is-hidden')
        this.instances[i] = new Flickity(container, options)
      })

      // load fullscreen on img click
      // if (element.classList.contains('is-default')) {
      // container.addEventListener('click', (e) => {
      //   console.log(e.target)
      //   if (e.target.tagName == 'IMG') {
      //     this.instances[i].viewFullscreen()
      //   }
      // })
      // }
    })
  }
}
