import throttle from 'lodash.throttle'

export class TopBtn {
  constructor() {
    const btn = document.querySelector('#js-tothetop')
    if (!btn) return

    btn.addEventListener('click', () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    })

    const checkScroll = () => {
      btn.classList.toggle('is-visible', window.scrollY > 400)
    }

    window.addEventListener('scroll', throttle(checkScroll, 300))
  }
}
