export default class LazyHandler {
  constructor(element, fn, options) {
    const observer = new IntersectionObserver((entries) => {
      if (entries.some(({ isIntersecting }) => isIntersecting)) {
        observer.disconnect()
        fn()
      }
    }, options)

    observer.observe(element)
  }
}
