export class Accordion {
  constructor() {
    const blocks = document.querySelectorAll('.block-q_a_accordion')
    if (!blocks) return
    this.onToggle = null
    this.active = null

    blocks.forEach((block, index) => {
      block.addEventListener('click', (e) => {
        const trigger = e.target.closest('.js-trigger')

        if (trigger) {
          if (this.active) this.active.classList.remove('is-active')

          if (this.active != trigger) {
            this.active = trigger
            this.active.classList.add('is-active')
          } else {
            this.active = null
          }

          if (this.onToggle) this.onToggle.call()
        }
      })
    })
  }
}
