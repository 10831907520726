// import { Dropdown } from './dropdown'

export class Navbar {
  constructor() {
    // this.body = document.querySelector('body')
    this.navbar = document.querySelector('nav.navbar')
    this.burger = this.navbar.querySelector('.navbar-burger')
    this.menu = this.navbar.querySelector('.navbar-menu')
    this.active = false

    this.burger.addEventListener('click', () => {
      this.active = !this.active
      // this.body.classList.toggle('is-clipped', this.active)
      this.menu.classList.toggle('is-active', this.active)
      this.burger.classList.toggle('is-active', this.active)
      this.burger.setAttribute('aria-expanded', this.active)
      document.documentElement.classList.toggle('is-clipped', this.active)
    })

    // this.dropdowns = this.navbar.querySelectorAll('.navbar-item.has-dropdown')
    // this.dropdowns.forEach((e) => {
    //   const dd = new Dropdown(e)
    // })
  }
}
