import 'iconify-icon'
import { Scroller } from './components/scroller'
import { TopBtn } from './components/topbtn'
import { Accordion } from './components/accordion'
import { Carousel } from './components/carousel'
import { Navbar } from './components/navbar'
import LazyHandler from './services/lazyhandler'

import '@/scss/base.scss'

class CAPP {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      const scrollMenu = new Scroller()
      const accordion = new Accordion()
      const carousel = new Carousel('.carousel.is-default', {
        prevNextButtons: false,
        pageDots: false,
        imagesLoaded: true,
        lazyLoad: 2,
        fullscreen: true,
      })

      const carouselDotted = new Carousel('.carousel.is-dotted', {
        prevNextButtons: false,
        // adaptiveHeight: true,
      })

      const topbtn = new TopBtn()
      const navbar = new Navbar()

      this.lazyLoadEmbeddedBlocks()
    })
  }

  lazyLoadEmbeddedBlocks() {
    const figures = document.querySelectorAll('.block-embed_block figure')

    figures.forEach((e) => {
      const template = e.querySelector('template')

      new LazyHandler(e, () => {
        e.append(template.content)
        template.remove()
      })
    })
  }
}

export const APP = new CAPP()
