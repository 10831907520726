export class Scroller {
  constructor() {
    this.active = null
    this.btns = document.querySelectorAll('[data-scroll]')

    document.addEventListener('click', (e) => {
      this.btns.forEach((b) => {
        if (b.contains(e.target)) {
          const target = document.querySelector(b.dataset.scroll)

          if (target) {
            // if (this.active) this.active.classList.remove('is-active')
            this.active = b
            // this.active.classList.add('is-active')

            target.scrollIntoView({
              behavior: 'smooth',
            })
          }
        }
      })
    })
  }
}
